import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, NavLink } from 'reactstrap';
import '../scss/ImageMiniature.scss';

export class ImageMiniature extends Component {
	static displayName = ImageMiniature.name;

	constructor(props) {
		super(props);

		this.state = {
			targetUrl: props.targetUrl,
			url: props.url,
			title: props.title
		};
	}

	render() {
		return (
			<Col xl="3" lg="3" md="4" sm="6" xs="12" className="m-0 p-1"  >
				<NavLink tag={Link} to={this.state.targetUrl} className="p-0 miniature-container">
					<img className="miniature" src={this.state.url} alt={this.state.title} />
					<div className="miniature-overlay" />
				</NavLink>
			</Col>);
	}
}