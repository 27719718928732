import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Const } from '../utilities/variables';

export class PageTitle extends Component {
	static displayName = PageTitle.name;

	constructor(props) {
		super(props);

		if (props.title) {
			this.state = {
				title: props.title + ' - ' + Const.PageTitle
			};
		} else {
			this.state = {
				title: Const.PageTitle
			};
		}
	}

	render() {
		return (
			<Helmet>
				<title>{this.state.title}</title>
			</Helmet>);
	}
}