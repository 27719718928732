
export const Const = {
	PageUrl: "https://gustowska-kucharska.com",
	PageTitle: "Magdalena Gustowska-Kucharska Portfolio",
	PageAuthor: "Magdalena Gustowska-Kucharska",
	FbUrl: "https://fb.me/madziullo",
	InstagramUrl: "https://www.instagram.com/madziullo/",
	BehanceUrl: "https://www.behance.net/magdalena_gustowska",

	ImageSizes: [
		['Large', 1801],
		['Medium', 641],
		['Small', 321]
	]
};