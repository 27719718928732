import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { GalleryPageWithRouter } from './views/GalleryPage';
import { Layout } from './components/Layout';
import './scss/App.scss';
import { AboutMe } from './views/AboutMe';
import { Contact } from './views/Contact';
import { Home } from './views/Home';

export default class App extends Component {
	static displayName = App.name;

	render() {
		return (
			<Layout>
				<Switch>
					<Route exact path='/' component={Home} />
					<Route path='/about' component={AboutMe} />
					<Route path='/contact' component={Contact} />
					<Route path='/gallery' component={GalleryPageWithRouter} />
				</Switch>
			</Layout>
		);
	}
}
