import React, { Component } from 'react';
import photo from '../img/Magda.jpg';
import { PageTitle } from '../components/PageTitle';

export class AboutMe extends Component {
	static displayName = AboutMe.name;

	render() {
		return (
			<div>
				<PageTitle title="About me" />

				<img className="float-right mx-3 mt-4 profile-pic"  src={photo} alt="Picture of Magdalena Gustowska-Kucharska"/>

				<h3>Magdalena Gustowska-Kucharska</h3>
				<p>was born and raised in Torun, Poland. She was fascinated by art since she was able to express herself in early childhood. In 2016 she graduated from Nicolaus Copernicus University in Torun with Master of Fine Arts degree. Currently she is living in Bellevue, Washington, where she moved in 2017. During her studies, Magdalena was trained in fine prints, animation, design as well as painting and drawing. No matter what medium Gustowska-Kucharska is choosing – the most important in her art are details, textures and colors. She wants to prove that even a car engine can be as beautiful as a flower.</p>

				<br />

				<h3>Artist Statement</h3>

				<p>Nowadays people are too busy to focus on details, stop for a second and look around. I want my art to show them things that they are missing every day. Sometimes I spend hundreds of hours on one illustration to hand over the real nature of an object. Technology was invented to make our lives easier. I appreciate it - that’s why I am using digital tablet the same often as a piece of paper and a pen. I believe that the real magic is in your hand and your mind, regardless of which tool you decide to use. I would like my art to make people more detail oriented - that’s why I am trying to create an interesting world full of textures, lines and colors.</p>

			</div>
		);
	}
}
