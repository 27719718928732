import React, { Component } from 'react';
import { Spinner, Row, Col } from 'reactstrap';

export class LoadingIndicator extends Component {
	static displayName = LoadingIndicator.name;

	render() {
		return (
			<Row>
				<Col lg="12" className="text-center">
					<Spinner color="dark" role="status" className="spinner">
						Loading...
					</Spinner>
				</Col>
			</Row>
		);
	}
}