import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
	static displayName = Layout.name;

	render() {
		return (
			<div>
				<Container fluid={true}>
					<Row>
						<Col className="bd-sidebar" xl="2" md="3">
							<NavMenu />
						</Col>

						<main className="col-md-9 ml-sm-auto col-xl-10 pt-3 px-4" role="main">
							{this.props.children}
						</main>
					</Row>
				</Container>
			</div>
		);
	}
}
