const LastUpdateTimeKey = '_lastUpdate_';
const CacheExpiryHours = 1;

export class DataManager {
	static displayName = DataManager.name;

	static fetchCarouselData(successCallback, errorCallback) {
		return this._fetchData("_carousel_", '/api/home/carousel', successCallback, errorCallback);
	}

	static fetchGalleryData(galleryId, successCallback, errorCallback) {
		return this._fetchData(galleryId, '/api/gallery/' + galleryId, successCallback, errorCallback);
	}

	static _fetchData(dataKey, requestUrl, successCallback, errorCallback) {
		if (!dataKey || typeof dataKey !== 'string') {
			console.error('dataKey was incorrect.');
			return null;
		}

		if (!errorCallback) {
			errorCallback = error => {
				console.error("Error while calling gallery api: " + error);
			};
		}

		// check if the data is already in storage
		let retrievedData = this._getCachedData(dataKey);
		if (!retrievedData || this._shouldRefreshCache()) {
			console.log("Fetching data.");
			// call the api
			fetch(requestUrl,
				{
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Accept-Encoding': 'gzip, deflate, br'
					}
				})
				.then(response => response.json())
				.then(data => {
					this._setCachedData(dataKey, data);

					// Update the lastUpdate field in storage
					this._setCachedData(LastUpdateTimeKey, new Date());

					successCallback(data);
				})
				.catch(errorCallback);
		} else {
			console.log("Using cached data.");

			successCallback(retrievedData);
		}
	}

	static _primitiveCache = {};

	static _isLocalStorageSupported() {
		return typeof (Storage) !== "undefined";
	}

	static _getCachedData(keyId) {
		if (this._isLocalStorageSupported()) {
			let serializedValue = sessionStorage.getItem(keyId);

			if (serializedValue) {
				return JSON.parse(serializedValue);
			}

			return null;
		}
		else {
			return this._primitiveCache[keyId];
		}
	}

	static _setCachedData(keyId, data) {
		if (this._isLocalStorageSupported) {
			sessionStorage.setItem(keyId, JSON.stringify(data));
		}
		else {
			this._primitiveCache[keyId] = data;
		}
	}

	static _shouldRefreshCache() {
		let lastUpdate = this._getCachedData(LastUpdateTimeKey);

		if (lastUpdate) {
			return new Date(lastUpdate).addHours(CacheExpiryHours) < new Date();
		}

		return true;
	}
}

Date.prototype.addHours = function (h) {
	this.setHours(this.getHours() + h);
	return this;
}