import React, { Component } from 'react';
import { Const } from '../utilities/variables';
import { PageTitle } from '../components/PageTitle';

export class Contact extends Component {
	static displayName = Contact.name;

	render() {
		return (
			<div>
				<PageTitle title="Contact me" />

				<h1 className="page-title">Contact me!</h1>

				<p>You can reach me at <a href="mailto:m.gustowska.kucharska@gmail.com">m.gustowska.kucharska@gmail.com</a> or find me on my <a href={Const.FbUrl}>Facebook page</a>.</p>
			</div>
		);
	}
}
