import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { ImageMiniature } from '../components/ImageMiniature';
import { PageTitle } from '../components/PageTitle';

export class MiniaturesView extends Component {
	static displayName = MiniaturesView.name;

	constructor(props) {
		super(props);

		this.state = {
			gallery: props.galleryData,
		};
	}

	render() {
		return (
			<div>
				<PageTitle title={this.state.gallery.name} />
				<Row className="mb-3">
					<Col lg="12">
						<h1 className="page-title font-weight-light text-center text-lg-left mt-0 mb-0">{this.state.gallery.name}</h1>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<Row className="text-center text-lg-left">
							{this.state.gallery.images.map((image, index) =>
								<ImageMiniature title={image.title} url={image.squaredThumbnailUrl} key={index} targetUrl={image.pageUrl} />)}
						</Row>
					</Col>
				</Row>
			</div>
		);
	}
}
