import React, { Component } from 'react';
import { FullImageView } from './FullImageView';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { MiniaturesView } from './MiniaturesView';
import { DataManager } from '../utilities/datamanager';
import { withRouter } from "react-router";
import { Route, Switch } from 'react-router-dom';

export class GalleryPage extends Component {
	static displayName = GalleryPage.name;

	render() {
		return (
			<Switch>
				<Route path='/gallery/:galleryId/' exact component={withGalleryData(MiniaturesView)} />
				<Route path='/gallery/:galleryId/:imageId' component={withGalleryData(FullImageView)} />
			</Switch>
		);
	}
}

function withGalleryData(WrappedComponent) {
	return class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				loading: true,
				galleryId: props.match.params.galleryId,
				imageId: props.match.params.imageId,
				galleryData: null,
				imageData: null,
				imageIndex: -1
			};
		}

		componentDidMount() {
			DataManager.fetchGalleryData(this.state.galleryId,
				data => {
					this.setState(prevState => {
						let imageIndex = data.images.findIndex(img => img.id === prevState.imageId);

						return {
							galleryData: data,
							imageData: data.images[imageIndex],
							imageIndex: imageIndex,
							loading: false
						}
					})
				});
		}

		render() {
			if (this.state.loading) {
				return (<LoadingIndicator />);
			}

			return <WrappedComponent galleryData={this.state.galleryData} imageData={this.state.imageData} imageIndex={this.state.imageIndex} {...this.props} />;
		}
	};
}

export const GalleryPageWithRouter = withRouter(GalleryPage);