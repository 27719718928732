import React, { Component } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Nav, Navbar, NavbarBrand, NavItem, NavLink, Collapse, NavbarToggler } from 'reactstrap';
import '../scss/NavMenu.scss';
import { Const } from '../utilities/variables';
import fbIcon from '../img/fb-icon.png';
import instagramIcon from '../img/instagram-icon.png';
import behanceIcon from '../img/behance-icon.png';
import { ReactComponent as Logo } from '../img/logo.svg';

export class NavMenu extends Component {
	static displayName = NavMenu.name;

	constructor(props) {
		super(props);

		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true
		};
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	render() {
		return (
			<Navbar className="navbar-collapse flex-column" expand="md" light>
				<div className="flex-row w-100">
					<NavbarBrand tag={RRNavLink} to="/" className="text-nowrap text-center logo-container">
						<Logo />
					</NavbarBrand>
					<NavbarToggler onClick={this.toggleNavbar} className="float-right" />
				</div>

				<Collapse isOpen={!this.state.collapsed} navbar className="flex-column">
					<Nav navbar className="d-block w-100" >
						<NavItem>
							<NavLink tag={RRNavLink} to="/" activeClassName='active' exact>Home</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={RRNavLink} to="/gallery/illustration" activeClassName='active'>Illustration</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={RRNavLink} to="/gallery/digital_art" activeClassName='active' >Digital Art</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={RRNavLink} to="/gallery/painting" activeClassName='active'>Painting</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={RRNavLink} to="/about" activeClassName='active'>About me</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={RRNavLink} to="/contact" activeClassName='active'>Contact</NavLink>
						</NavItem>
					</Nav>
					<div className="social-media-container py-3 my-5">
						<a className="social-icon" href={Const.FbUrl}>
							<img src={fbIcon} className="social-media" alt="Find me on Facebook!" />
						</a>
						<a className="social-icon" href={Const.InstagramUrl}>
							<img src={instagramIcon} className="social-media" alt="Find me on Instagram!" />
						</a>
						<a className="social-icon" href={Const.BehanceUrl}>
							<img src={behanceIcon} className="social-media" alt="Find me on Behance!" />
						</a>
					</div>
					<div className="footer-copyright text-center py-3">
						<small className="text-muted">
							Copyright &copy; {new Date().getFullYear()} <a href={Const.PageUrl + '/contact'} > {Const.PageAuthor}</a>. All rights reserved.
						</small>
					</div>
				</Collapse>
			</Navbar>

		);
	}
}
