import React, { Component } from 'react';
import { Row, Col, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../scss/FullImageView.scss';
import { Const } from '../utilities/variables';
import { PageTitle } from '../components/PageTitle';

export class FullImageView extends Component {
	static displayName = FullImageView.name;

	constructor(props) {
		super(props);

		this.renderPicture = this.renderPicture.bind(this);

		let nextImageIndex = props.imageIndex + 1;
		let prevImageIndex = props.imageIndex - 1;

		if (nextImageIndex >= props.galleryData.images.length) {
			nextImageIndex = 0;
		}

		if (prevImageIndex < 0) {
			prevImageIndex = props.galleryData.images.length - 1;
		}

		this.state = {
			url: props.imageData.url,
			title: props.imageData.title,
			technique: props.imageData.technique,
			creationDate: new Date(props.imageData.creationDate),
			galleryUrl: props.galleryData.url,
			nextImage: props.galleryData.images[nextImageIndex],
			previousImage: props.galleryData.images[prevImageIndex]
		};
	}

	renderPicture() {
		return (
			<picture>
				{Const.ImageSizes.map(size =>
					<source media={"(min-width: " + size[1] + "px)"} srcSet={this.state.url + "?size=" + size[0]} />
				)}
				<source srcSet={this.state.url + "?size=Thumbnail" } />

				{<img className="img-fluid full-img" src={this.state.url} alt={this.state.title} />}
			</picture>
		);
	}

	render() {
		return (
			<div>
				<PageTitle title={this.state.title} />

				<Row className="mb-3">
					<Col>
						<span>
							<h1 className="font-weight-light text-center text-lg-left mt-0 mb-0 float-lg-left text-muted">
								{this.state.title},
								<small>{this.state.technique}, {this.state.creationDate.getFullYear()}</small>
							</h1>
						</span>

						<NavLink tag={Link} to={this.state.galleryUrl} className="text-right align-middle float-lg-right">Back to gallery</NavLink>
					</Col>
				</Row>

				<Row>
					<Col lg="12">
						<div className="full-img-container text-center">
							{this.renderPicture()}

							<div className="full-img-overlay">

								<NavLink className="slide-nav nav-left float-left text-left" tag={Link} to={this.state.previousImage.pageUrl}>
									<span>&#10094;</span>
								</NavLink>

								<NavLink className="slide-nav nav-right float-right text-right" tag={Link} to={this.state.nextImage.pageUrl}>
									<span>&#10095;</span>
								</NavLink>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}