import React, { Component } from 'react';
import { DataManager } from '../utilities/datamanager';
import { LoadingIndicator } from '../components/LoadingIndicator';
import '../scss/Home.scss';
import { Link } from 'react-router-dom';
import { Col, Row, NavLink } from 'reactstrap';
import { PageTitle } from '../components/PageTitle';

const NumberOfRows = 5;
export class Home extends Component {
	static displayName = Home.name;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			items: {},
			layout: []
		};

		this.renderMosaic = this.renderMosaic.bind(this);
		this.calculateLayout = this.calculateLayout.bind(this);
	}

	renderMosaic() {
		return (
			<Row className="mt-5">
				<Col>
					<PageTitle />
					{(this.state.layout.map((items, index) => {

						return (
							<Row className="justify-content-center" key={"row_" + index}>
								{items.map((item, index) => {

									return (
										<div className="grid-item" key={index}>
											<NavLink tag={Link} to={item.pageUrl} className="p-0 miniature-container">
												<img className="img-fluid" src={item.thumbnailUrl} alt={item.title} />
												<div className="miniature-overlay" />
											</NavLink>
										</div>);
								})}
							</Row>
						);
					}
					))}
				</Col>
			</Row>
		);
	}

	componentDidMount() {
		DataManager.fetchCarouselData(
			data => {
				this.setState({
					items: data,
					loading: false,
					layout: this.calculateLayout(data)
				})
			});
	}

	calculateLayout(images) {
		let layout = [];
		const step = -((NumberOfRows -1) / images.length);

		images.forEach((image, index) => {
			let rowIndex = Math.round(step * index + NumberOfRows);

			if (!layout[rowIndex]) {
				layout[rowIndex] = [image];
			} else {
				layout[rowIndex].push(image);
			}
		});

		return layout;
	}

	render() {
		const content = this.state.loading ? (<LoadingIndicator />) : this.renderMosaic();

		return (
			<div>
				{content}
			</div>
		);
	}
}
